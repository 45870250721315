import React, { Component } from 'react'
import Link from '../utils/link'

import { Plus } from './icons'

class CompanyOffering extends Component {

  render() {

    let { intro, content, offerings } = this.props

    return (
      <section className='company-offering'>
        <div className='company-offering__inner'>
          <div className='company-offering__flex'>
            <div className='company-offering__left'>
              <div className='company-offering__logo'>
                <div className='company-offering__logo-text'>Mara<span>+</span>Co</div>
              </div>
              <div className='company-offering__intro' dangerouslySetInnerHTML={{ __html: intro }} />
            </div>
            <div className='company-offering__right'>
              <div className='company-offering__content' dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
          { offerings &&
            <div className='company-offering__items'>
              { offerings.map((el, i) => (
                <div className='company-offering__item' key={i}>
                  <div className='company-offering__image'>
                    <img src={el.image?.localFile?.childImageSharp.original.src} alt={el.title} />
                    <Plus color='#FFFFFF' />
                  </div>
                  { el.title && <h4>{el.title}</h4> }
                  { el.subtitle && <span>{el.subtitle}</span> }
                  <Link className='company-offering__link' to={el.link} />
                </div>
              ))}
            </div>
          }
        </div>
      </section>
    )
  }
}

export default CompanyOffering
