import React, { Component } from 'react'
import Link from '../utils/link'

import { Plus } from './icons'

class Team extends Component {

  render() {

    let { title, team } = this.props

    return (
      <section className='team-grid'>
        <div className='team-grid__inner'>
          <div className='team-grid__title'>
            <h3>{title}</h3>
          </div>
          <div className='team-grid__items'>
            { team && team.map((el, i) => (
              <div className='team' key={i}>
                <div className='team__image'>
                  <img src={el.acf.profileThumb?.localFile?.childImageSharp.original.src} alt={el.title} />
                  <Plus color='#FFFFFF' />
                </div>
                <div className='team__title'>
                  <h4>{el.title}</h4>
                  <span>{el.acf.position}</span>
                </div>
                <Link className='team__link' to={`/team/${el.slug}`} />
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

export default Team
