import React, { Component } from 'react'
import Link from '../utils/link'
import Slider from 'react-slick'

import { RightArrow } from './icons'
import PropertyCard from './cards/property'

function PrevArrow(props) {
  let { onClick, style, className } = props
  let attr = { onClick, style, className }
  return (
    <span 
      {...attr}
    >
      <RightArrow color={'#C64867'} />
    </span>
  )
}

function NextArrow(props) {
  let { onClick, style, className } = props
  let attr = { onClick, style, className }
  return (
    <span 
      {...attr}
    >
      <RightArrow color={'#C64867'} />
    </span>
  )
}

class FeaturedProperties extends Component {

  render() {

    let { title, properties } = this.props

    const settings = {
      infinite: true,
      speed: 600,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
      adaptiveHeight: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }

    return (
      <section className='featured-properties'>
        <div className='featured-properties__inner'>
          <div className='featured-properties__title'>
            <h3>{title}</h3>
            <Link className='btn' to='/buy'>View more</Link>
          </div>
          <div className='featured-properties__items'>
            { properties &&
              <Slider {...settings}>
                { properties.map((el, i) => (
                  <PropertyCard key={i} {...el} />
                ))}
              </Slider>
            }
          </div>
          <Link className='featured-properties__mobile-btn btn' to='/buy'>View more</Link>
        </div>
      </section>
    )
  }
}

export default FeaturedProperties
