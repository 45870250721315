import React, { Component } from 'react'

class MobileDesktopVideo extends Component {

  state = {
    isMobile: false
  }

  throttledHandleWindowResize = () => {
    if (typeof window !== 'undefined') {
      this.setState({ isMobile: window.innerWidth < 924 })
    }
  }

  componentDidMount() {
    this.throttledHandleWindowResize()
    window.addEventListener('resize', this.throttledHandleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }

  render() {
    let { mobileVideo, video } = this.props
    const { isMobile } = this.state

    if ( isMobile ) {
      return (
        <video src={mobileVideo} autoPlay loop muted playsInline />
      )
    } else {
      return (
        <video src={video} autoPlay loop muted playsInline />
      )
    }
  }
}

export default MobileDesktopVideo