import React, { Component } from 'react'
import Link from '../utils/link'

class Testimonials extends Component {

  render() {

    let { image, title, button, testimonials } = this.props

    return (
      <section className='testimonials'>
        <div className='testimonials__inner'>
          <img src={image?.localFile?.childImageSharp.original.src} alt='Mara+Co - Services' />
          <div className='testimonials__cols'>
          	<div className='testimonials__col'>
          		<h3>{title}</h3>
          		<Link className='btn btn--desktop' to={button.link}>{button.text}</Link>
          	</div>
          	{testimonials && testimonials.map((el, i) => (
	          	<div className='testimonials__col' key={i}>
	          		<div className='testimonials__quote' dangerouslySetInnerHTML={{ __html: el.quote }} />
	          		<span className='testimonials__name'>{el.name}</span>
	          	</div>
	          ))}
          </div>
          <Link className='btn btn--mobile' to={button.link}>{button.text}</Link>
        </div>
      </section>
    )
  }
}

export default Testimonials