import React, { Component } from 'react'
import Link from '../utils/link'
import Slider from 'react-slick'

import { RightArrow } from './icons'

function PrevArrow(props) {
  let { onClick, style, className } = props
  let attr = { onClick, style, className }
  return (
    <span 
      {...attr}
    >
      <RightArrow color={'#C64867'} />
    </span>
  )
}

function NextArrow(props) {
  let { onClick, style, className } = props
  let attr = { onClick, style, className }
  return (
    <span 
      {...attr}
    >
      <RightArrow color={'#C64867'} />
    </span>
  )
}

class OurDifference extends Component {

  render() {

    let { title, image, difference } = this.props

    const diffSettings = {
      infinite: true,
      speed: 600,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
      adaptiveHeight: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }

    return (
      <section className='difference'>
        <div className='difference__inner'>
        	<h2>{title}</h2>
          <img src={image?.localFile?.childImageSharp.original.src} alt='Mara+Co - Services' />
          <div className='difference__items'>
            { difference &&
              <Slider {...diffSettings}>
                { difference.map((el, i) => (
                  <div className='difference__item' key={i}>
                    <h4>{el.title}</h4>
                    <div className='difference__content' dangerouslySetInnerHTML={{ __html: el.content }} />
                  </div>
                ))}
              </Slider>
            }
          </div>
        </div>
      </section>
    )
  }
}

export default OurDifference
