import React, { Component } from 'react'
import Link from '../utils/link'

class ContactImage extends Component {

  render() {

    let { content, image, call, email, location, social } = this.props

    return (
      <section className='contact-image'>
        <div className='contact-image__inner'>
          <div className='contact-image__content'>
            <div className='contact-image__text' dangerouslySetInnerHTML={{ __html: content }} />
            <div className='contact-image__items'>
              <div className='contact-image__item'>
                <p>{call.title}</p>
                { call.list &&
                  <ul>
                    { call.list.map((el, i) => (
                      <li key={i}>
                        <Link to={el.link}>{el.text}</Link>
                      </li>
                    ))}
                  </ul>
                }
              </div>
              <div className='contact-image__item'>
                <p>{email.title}</p>
                { email.list &&
                  <ul>
                    { email.list.map((el, i) => (
                      <li key={i}>
                        <Link to={el.link}>{el.text}</Link>
                      </li>
                    ))}
                  </ul>
                }
              </div>
              <div className='contact-image__item'>
                <p>{location.title}</p>
                { location.list &&
                  <ul>
                    { location.list.map((el, i) => (
                      <li key={i}>
                        <Link to={el.link}>{el.text}</Link>
                      </li>
                    ))}
                  </ul>
                }
              </div>
              <div className='contact-image__item'>
                <p>{social.title}</p>
                { social.list &&
                  <ul>
                    { social.list.map((el, i) => (
                      <li key={i}>
                        <Link to={el.link}>{el.text}</Link>
                      </li>
                    ))}
                  </ul>
                }
              </div>
            </div>
          </div>
          <div className='contact-image__image'>
            <img src={image?.localFile?.childImageSharp.original.src} alt={`Mara+Co`} />
          </div>
        </div>
      </section>
    )
  }
}

export default ContactImage
