import React, { Component } from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class AppraisalsForm extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    enquiry: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => {
      this.setState({result: 'success', msg: ''})
    })
    .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'appraisals',
      className: 'form form--flex',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<p className='contact__success'>Thanks for getting in contact with us.<br /> A member of our team will be in touch soon.</p>)

    return (
      <form {...props}>
        <div className='form__left'>
          <div className='form__row'>
            <input type='text' name='name' placeholder='Your name' onChange={this.handleChange} required />
          </div>
          <div className='form__row'>
            <input type='email' name='email' placeholder='Your email' onChange={this.handleChange} required  />
          </div>
          <div className='form__row'>
            <input type='text' name='phone' placeholder='Contact number' onChange={this.handleChange} required  />
          </div>
          <div className='form__row form__row--select'>
            <select name='preferred_contact_method' onChange={this.handleChange}>
              <option value=''>Preferred contact method</option>
              <option value='call'>Call</option>
              <option value='sms'>SMS</option>
              <option value='email'>Email</option>
            </select>
          </div>
          <div className='form__row'>
            <label>What kind of appraisal are you looking for?</label>
            <div className='form__radio-group'>
              <div className='form__radio'>
                <input type='radio' id='sales-appraisal' name="appraisal_type" value="Sales" onChange={this.handleChange} />
                <label htmlFor='sales-appraisal'>Sales Appraisal</label>
              </div>
              <div className='form__radio'>
                <input type='radio' id='lease-appraisal' name="appraisal_type" value="Lease" onChange={this.handleChange} />
                <label htmlFor='lease-appraisal'>Rental Appraisal</label>
              </div>
            </div>
          </div>
        </div>
        <div className='form__right'>
          <div className='form__row'>
            <input type='text' name='appraisal_address' placeholder='Please enter address of property to be appraised' onChange={this.handleChange} required  />
          </div>
          <div className='form__row'>
            <textarea name='enquiry' placeholder='Additional Comments (Optional)' onChange={this.handleChange} />
          </div>
          <div className='form__row'>
            <div className='form__checkbox'>
              <input type='checkbox' name='register' id='register-field' className='form__input' onChange={this.handleChange}  />
              <label htmlFor='register-field'>Yes please, I’d like to receive exclusive Mara + Co e-property sales updates.</label>
            </div>
          </div>
          <div className='form__row form__row--submit'>
            <button type='submit' className='btn btn--border'>Request my appraisal</button>
          </div>
        </div>
      </form>
    )
  }
}

export default AppraisalsForm