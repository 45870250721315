import React, { Component } from 'react'
import Link from '../utils/link'

import { DownArrow } from './icons'
import Logo from '../assets/images/logo-pink.svg'
import Map from './map'
import ContactForm from './contact-form'

class Contact extends Component {

  render() {

    let { title, backgroundImage, map, content, call, email, location, social } = this.props

    return (
      <section className='contact'>
        <div className='contact__inner'>
          <div className='contact__title'>
            <h1>{title}</h1>
            <DownArrow color='#C64867' />
          </div>
          <div className='contact__image'>
            <img src={backgroundImage?.localFile?.childImageSharp.original.src} alt={title} />
            <img className='contact__logo' src={Logo} alt={title} />
          </div>
          <div className='contact__wrapper'>
            <div className='contact__map'>
              <Map {...map} />
              <div className='contact__items'>
                <div className='contact__item'>
                  <p>{call.title}</p>
                  { call.list &&
                    <ul>
                      { call.list.map((el, i) => (
                        <li key={i}>
                          <Link to={el.link}>{el.text}</Link>
                        </li>
                      ))}
                    </ul>
                  }
                </div>
                <div className='contact__item'>
                  <p>{email.title}</p>
                  { email.list &&
                    <ul>
                      { email.list.map((el, i) => (
                        <li key={i}>
                          <Link to={el.link}>{el.text}</Link>
                        </li>
                      ))}
                    </ul>
                  }
                </div>
                <div className='contact__item'>
                  <p>{location.title}</p>
                  { location.list &&
                    <ul>
                      { location.list.map((el, i) => (
                        <li key={i}>
                          <Link to={el.link}>{el.text}</Link>
                        </li>
                      ))}
                    </ul>
                  }
                </div>
                <div className='contact__item'>
                  <p>{social.title}</p>
                  { social.list &&
                    <ul>
                      { social.list.map((el, i) => (
                        <li key={i}>
                          <Link to={el.link}>{el.text}</Link>
                        </li>
                      ))}
                    </ul>
                  }
                </div>
              </div>
            </div>
            <div className='contact__form'>
              <div className='contact__content' dangerouslySetInnerHTML={{ __html: content }} />
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Contact
