import React, { Component } from 'react'
import Link from '../utils/link'
import Slider from 'react-slick'

import { RightArrow } from './icons'

function PrevArrow(props) {
  let { onClick, style, className } = props
  let attr = { onClick, style, className }
  return (
    <span 
      {...attr}
    >
      <RightArrow color={'#C64867'} />
    </span>
  )
}

function NextArrow(props) {
  let { onClick, style, className } = props
  let attr = { onClick, style, className }
  return (
    <span 
      {...attr}
    >
      <RightArrow color={'#C64867'} />
    </span>
  )
}

class Services extends Component {

  render() {

    let { image, services } = this.props

    const servicesSettings = {
      infinite: true,
      speed: 600,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 2,
      slidesToScroll: 2,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
      adaptiveHeight: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    }

    return (
      <section className='services'>
        <div className='services__inner'>
          <img src={image?.localFile?.childImageSharp.original.src} alt='Mara+Co - Services' />
          <div className='services__items'>
            { services &&
              <Slider {...servicesSettings}>
                { services.map((el, i) => (
                  <div className='services__item' key={i}>
                    <h4>{el.title}</h4>
                    <div className='services__content' dangerouslySetInnerHTML={{ __html: el.content }} />
                  </div>
                ))}
              </Slider>
            }
          </div>
        </div>
      </section>
    )
  }
}

export default Services
