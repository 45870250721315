import React, { Component } from 'react'
import Link from '../utils/link'

import { Plus } from './icons'

class About extends Component {

  render() {

    let { topTitle, topContent, smallImage, largeImage, bottomContent, bottomTitle } = this.props

    return (
      <section className='about'>
        <div className='about__inner'>
          <div className='about__top'>
          	<h2>{topTitle}</h2>
          	<div className='about__top-content' dangerouslySetInnerHTML={{ __html: topContent }} />
          </div>
          <div className='about__images'>
          	<img className='about__small' src={smallImage?.localFile?.childImageSharp.original.src} alt={topTitle+' '+bottomTitle} />
          	<img className='about__large' src={largeImage?.localFile?.childImageSharp.original.src} alt={topTitle+' '+bottomTitle} />
          </div>
          <div className='about__bottom'>
          	<div className='about__bottom-content' dangerouslySetInnerHTML={{ __html: bottomContent }} />
          	<h2><Plus color={'#C64867'} /><span>{bottomTitle}</span></h2>
          </div>
        </div>
      </section>
    )
  }
}

export default About
