import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import Seo from '../components/seo'

import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
        { page.acf.contentBlocks && page.acf.contentBlocks.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'page'))  }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        contentBlocks {
          ... on WpPage_Acf_ContentBlocks_HeroBanner {
            fieldGroupName
            backgroundImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            mobileImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            intro
            video
            videoMobile
          }
          ... on WpPage_Acf_ContentBlocks_CompanyOffering {
            fieldGroupName
            intro
            content
            offerings {
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              title
              subtitle
              link
            }
          }
          ... on WpPage_Acf_ContentBlocks_FeaturedProperties {
            fieldGroupName
            title
            properties {
              ... on WpProperty {
                databaseId
                title
                slug
                propertyAddress {
                  hideAddress
                  streetAddress
                  suburb
                }
                propertyData {
                  bathrooms
                  bedrooms
                  type
                  carPorts
                  carSpaces
                  garages
                }
                propertyImages {
                  url
                  small
                  large
                }
                propertyListing {
                  searchPrice
                  marketingStatus
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Team {
            fieldGroupName
            title
            team {
              ... on WpTeamMember {
                title
                slug
                acf {
                  profileThumb {
                    localFile {
                      childImageSharp {
                        original {
                          src
                        }
                      }
                    }
                  }
                  position
                }
                teamDetails {
                  email
                  firstName
                  hideMobileOnWeb
                  id
                  jobTitle
                  lastName
                  mobile
                  phone
                  role
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Contact {
            fieldGroupName
            title
            backgroundImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            map {
              latitude
              longitude
            }
            call {
              title
              list {
                text
                link
              }
            }
            email {
              title
              list {
                text
                link
              }
            }
            location {
              title
              list {
                text
                link
              }
            }
            social {
              title
              list {
                text
                link
              }
            }
            content
          }
          ... on WpPage_Acf_ContentBlocks_Appraisals {
            fieldGroupName
            title
            intro
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            content
          }
          ... on WpPage_Acf_ContentBlocks_QrPage {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            video
            address
            specs {
              label
              value
            }
            agent {
              ... on WpTeamMember {
                title
                slug
                acf {
                  profileImage {
                    localFile {
                      childImageSharp {
                        original {
                          src
                        }
                      }
                    }
                  }
                  position
                  phone {
                    text
                    link
                  }
                  email {
                    link
                  }
                  bio
                }
              }
            }
            title
            content
            formTitle
          }
          ... on WpPage_Acf_ContentBlocks_ContactImage {
            fieldGroupName
            content
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            call {
              title
              list {
                text
                link
              }
            }
            email {
              title
              list {
                text
                link
              }
            }
            location {
              title
              list {
                text
                link
              }
            }
            social {
              title
              list {
                text
                link
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Services {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            services {
              title
              content
            }
          }
          ... on WpPage_Acf_ContentBlocks_Testimonials {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
            button {
              text
              link
            }
            testimonials {
              quote
              name
            }
          }
          ... on WpPage_Acf_ContentBlocks_OurDifference {
            fieldGroupName
            title
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            difference {
              title
              content
            }
          }
          ... on WpPage_Acf_ContentBlocks_About {
            fieldGroupName
            topTitle
            topContent
            smallImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            largeImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            bottomContent
            bottomTitle
          }
          ... on WpPage_Acf_ContentBlocks_Cta {
            fieldGroupName
            backgroundColour
            backgroundImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
          }
        }
      }
    }
  }
`

export default PageTemplate
