import React, { Component } from 'react'

import MobileDesktopVideo from './mobile-desktop-video'

class HeroBanner extends Component {

  render() {

    let { backgroundImage, mobileImage, intro, video, videoMobile } = this.props

    return (
      <section className='hero-banner'>
        { video && 
          <MobileDesktopVideo video={video} mobileVideo={videoMobile} />
        }
        { !video &&
          <img className='hero-banner__bg hero-banner__bg--mobile' src={mobileImage?.localFile?.childImageSharp.original.src} alt={`Mara+Co`} />
        }
        { !video &&
          <img className='hero-banner__bg hero-banner__bg--desktop' src={backgroundImage?.localFile?.childImageSharp.original.src} alt={`Mara+Co`} />
        }
        <div className='hero-banner__wrapper'>
          <div className='hero-banner__inner'>
            <div className='hero-banner__top'>Mara</div>
            <div className='hero-banner__bottom'>
              <div className='hero-banner__intro' dangerouslySetInnerHTML={{ __html: intro }} />
              <div className='hero-banner__logo'><span>+</span>Co</div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HeroBanner
