import React, { Component } from 'react'
import { Plus } from './icons'
import QRForm from './qr-form'
import Link from '../utils/link'
import Sold from '../assets/images/sold-white.svg'
import Play from '../assets/images/play.svg'
import FsLightbox from 'fslightbox-react';

class QRPage extends Component {

  state = {
    videoLightbox: false,
  }

  render() {

    let { image, video, address, specs, agent, title, content, formTitle } = this.props
    let { videoLightbox } = this.state

    return (
      <section className='qr'>
        <div className='qr__inner'>
          <div className='qr__image'>
            <img src={image?.localFile?.childImageSharp?.original.src} alt={title} />
            <img className='sold' src={Sold} alt='Mara+C - Sold' /> 
            { video &&
              <img className='play' src={Play} alt='Play Video' onClick={() => this.setState({ videoLightbox: !videoLightbox })} />
            }
          </div>
          <div className='qr__title'>
            <div className='qr__address' dangerouslySetInnerHTML={{ __html: address }} />
            <div className='qr__aab'>
              <span className='first'>Above</span> <Plus color={'#C64867'} /> <span className='last'>beyond</span>
            </div>
          </div>
          <div className='qr__property'>
            <div className='qr__specs'>
              { specs?.length > 0 &&
                <ul>
                  { specs.map((el, i) => (
                    <li key={i}>
                      <span className='qr__label'>{el.label}</span>
                      <span className='qr__value'>{el.value}</span>
                    </li>
                  ))}
                </ul>
              }
            </div>
            <div className='qr__details'>
              <h2>{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
          <div className='qr__form-wrapper'>
            <div className='qr__agent'>
              { agent?.length > 0 &&
                <>
                  <div className='qr__agent-details'>
                    <h4>Agent</h4>
                    <span>{agent[0].title}</span>
                    <Link to={agent[0].acf.phone.link}>{agent[0].acf.phone.text}</Link>
                    <Link className='email' to={agent[0].acf.email.link}>Email Agent</Link>
                  </div>
                  <div className='qr__agent-image'>
                    <img src={agent[0].acf.profileImage?.localFile?.childImageSharp.original.src} alt={agent[0].title} />
                  </div>
                  <div className='qr__agent-bio' dangerouslySetInnerHTML={{ __html: agent[0].acf.bio }} />
                </>
              }
            </div>
            <div className='qr__form'>
              <h3>{formTitle}</h3>
              <QRForm />
            </div>
          </div>
        </div>
        { video &&
          <FsLightbox
            toggler={videoLightbox}
            sources={[
              <iframe
                frameborder="0"
                width="1920px"
                height="1080px"
                allow="autoplay; fullscreen"
                src={`https://www.youtube.com/embed/${video}?autoplay=1`}>
              </iframe>
            ]}
          />
        }
      </section>
    )
  }
}

export default QRPage