import React, { Component } from 'react'
import Link from '../utils/link'

import { DownArrow } from './icons'
import Logo from '../assets/images/logo-pink.svg'
import AppraisalsForm from './appraisals-form'

class Appraisals extends Component {

  render() {

    let { title, intro, image, content } = this.props

    return (
      <section className='contact contact--appraisals'>
        <div className='contact__inner'>
          <div className='contact__title'>
            <h1>{title}</h1>
            <div className='contact__intro' dangerouslySetInnerHTML={{ __html: intro }} />
            <DownArrow color='#404041' />
          </div>
          <div className='contact__image'>
            <img src={image?.localFile?.childImageSharp.original.src} alt={title} />
            <img className='contact__logo' src={Logo} alt={title} />
          </div>
          <div className='contact__wrapper'>
            <div className='contact__content' dangerouslySetInnerHTML={{ __html: content }} />
            <div className='contact__form'>
              <AppraisalsForm />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Appraisals
