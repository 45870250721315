import React from 'react'

import HeroBanner from '../components/hero-banner'
import CompanyOffering from '../components/company-offering'
import FeaturedProperties from '../components/featured-properties'
import Contact from '../components/contact'
import Testimonials from '../components/testimonials'
import OurDifference from '../components/our-difference'
import About from '../components/about'
import Services from '../components/services'
import Team from '../components/team'
import Cta from '../components/cta'
import Appraisals from '../components/appraisals'
import ContactImage from '../components/contact-image'
import QRPage from '../components/qr-page'

const renderBlock = (param, el, i, postType, func=null) => {
  console.log(param)
  param = param?.replace(postType, '')
  let block = {
  	'_Acf_ContentBlocks_HeroBanner' : (el, i) => <HeroBanner key={i} {...el} />,
    '_Acf_ContentBlocks_CompanyOffering' : (el, i) => <CompanyOffering key={i} {...el} />,
    '_Acf_ContentBlocks_FeaturedProperties' : (el, i) => <FeaturedProperties key={i} {...el} />,
    '_Acf_ContentBlocks_Contact' : (el, i) => <Contact key={i} {...el} />,
    '_Acf_ContentBlocks_Testimonials' : (el, i) => <Testimonials key={i} {...el} />,
    '_Acf_ContentBlocks_About' : (el, i) => <About key={i} {...el} />,
    '_Acf_ContentBlocks_Services' : (el, i) => <Services key={i} {...el} />,
    '_Acf_ContentBlocks_OurDifference' : (el, i) => <OurDifference key={i} {...el} />,
    '_Acf_ContentBlocks_Team' : (el, i) => <Team key={i} {...el} />,
    '_Acf_ContentBlocks_Cta' : (el, i) => <Cta key={i} {...el} />,
    '_Acf_ContentBlocks_Appraisals' : (el, i) => <Appraisals key={i} {...el} />,
    '_Acf_ContentBlocks_ContactImage' : (el, i) => <ContactImage key={i} {...el} />,
    '_Acf_ContentBlocks_QrPage' : (el, i) => <QRPage key={i} {...el} />,
  }[param]

  if (!block) return null

  return block(el, i)
}

export default renderBlock