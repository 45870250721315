import React, { Component } from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    enquiry: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => {
      this.setState({result: 'success', msg: ''})
    })
    .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'contact',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<p className='contact__success'>Thanks for getting in contact with us.<br /> A member of our team will be in touch soon.</p>)

    return (
      <form {...props}>
        <div className='form__row'>
          <input type='text' name='name' placeholder='Your name' onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <input type='email' name='email' placeholder='Your email' onChange={this.handleChange} required  />
        </div>
        <div className='form__row'>
          <input type='text' name='phone' placeholder='Contact number' onChange={this.handleChange} required  />
        </div>
        <div className='form__row'>
          <input type='text' name='subject' placeholder='How can we help?' onChange={this.handleChange} required  />
        </div>
        <div className='form__row'>
          <textarea name='enquiry' placeholder='Your enquiry' onChange={this.handleChange} />
        </div>
        <div className='form__row form__row--submit'>
          <button type='submit' className='btn btn--border'>Submit Enquiry</button>
        </div>
      </form>
    )
  }
}

export default ContactForm